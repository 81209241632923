import { render, staticRenderFns } from "./BaseWideTextBox.vue?vue&type=template&id=a47b2922&scoped=true"
import script from "./BaseWideTextBox.vue?vue&type=script&lang=js"
export * from "./BaseWideTextBox.vue?vue&type=script&lang=js"
import style0 from "./BaseWideTextBox.vue?vue&type=style&index=0&id=a47b2922&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a47b2922",
  null
  
)

export default component.exports