import { POSTAL_CODE_API_URL } from '@/utils/define'
import axios from 'axios'

export default {
  data() {
    return {
      mixinApiResultPostalCode: null
    }
  },
  methods: {
    mixinInputAddress: async function(objName) {
      try {
        const obj = this[objName]
        const zipcode =
          obj.postalCode.slice(0, 3) + '/' + obj.postalCode.slice(3) + '.json'
        const res = await axios.get(POSTAL_CODE_API_URL + zipcode)
        if (res.status === 200) {
          obj.prefectureId = this.$store.getters['master/getDataById'](
            'prefectures',
            Number(res.data.data[0].prefcode)
          )?.id
          const resAddress = res.data.data[0].ja
          obj.address =
            resAddress.address1 +
            resAddress.address2 +
            resAddress.address3 +
            resAddress.address4
        }
        this.mixinApiResultPostalCode = { existingPostalCodeFlg: true }
      } catch (error) {
        if (error.response?.status === 404) {
          this.mixinApiResultPostalCode = { existingPostalCodeFlg: false }
        }
      }
    }
  }
}
