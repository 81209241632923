<template>
  <div class="auto-input-form">
    <div class="body">
      <validation-provider
        class="validate"
        :rules="validationRules"
        :immediate="immediate"
        v-slot="{ errors }"
      >
        <div class="input-button">
          <base-text-box
            class="input"
            :maxlength="maxlength"
            :placeholder="placeholder"
            :inputStyle="inputStyle"
            v-model="input"
          />
          <base-button-plus
            class="button"
            :text="buttonText"
            :disabledFlg="disabled || value === '' || errors.length > 0"
            @click="$emit('click', input)"
          />
        </div>
        <div class="error">
          <div v-for="(error, index) in errors" :key="index">
            {{ $t(error) }}
          </div>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'AutoInputForm',

  components: {
    BaseTextBox,
    BaseButtonPlus,
    ValidationProvider
  },

  props: {
    validationRules: { type: Object },
    immediate: { type: Boolean, default: false },
    value: { type: [String, Number], default: '' },
    maxlength: { type: Number },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    inputStyle: { type: Object },
    buttonText: { type: String, default: '' }
  },

  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auto-input-form {
  display: flex;
  align-items: flex-start;
  > .body {
    @include validate-message();
    > .validate {
      width: 100%;
      > .input-button {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px 10px;
      }
    }
  }
}
</style>
