<template>
  <div class="full-name-input-form">
    <div class="last-name-form">
      <label>{{ $t('common.labelLastName') }}</label>
      <validation-provider
        rules="requiredRule|nameRule"
        v-slot="{ errors }"
        class="provider"
      >
        <base-owner-name-text-box
          v-model="owner.lastName"
          :placeholder="$t('common.placeholderLastName')"
        />
        <div class="error" data-test="last-name-err">{{ $t(errors[0]) }}</div>
      </validation-provider>
    </div>
    <div class="first-name-form">
      <label>{{ $t('common.labelFirstName') }}</label>
      <validation-provider
        rules="requiredRule|nameRule"
        v-slot="{ errors }"
        class="provider"
      >
        <base-owner-name-text-box
          v-model="owner.firstName"
          :placeholder="$t('common.placeholderFirstName')"
          class="first-name-input-box"
        />
        <div class="error" data-test="first-name-err">{{ $t(errors[0]) }}</div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import BaseOwnerNameTextBox from '@/components/parts/atoms/BaseOwnerNameTextBox.vue'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'FullNameInputForm',

  components: {
    BaseOwnerNameTextBox,
    ValidationProvider
  },

  props: {
    owner: {
      lastName: { type: String },
      firstName: { type: String }
    }
  }
}
</script>

<style lang="scss" scoped>
.full-name-input-form {
  width: 100%;
  display: flex;
  gap: 0 28px;
  @media (max-width: $global-media-width) {
    gap: 0 18px;
  }
  @media (max-width: 625px) {
    flex-direction: column;
    row-gap: 28px;
  }
  > .last-name-form,
  .first-name-form {
    display: flex;
    align-items: center;
    @include validate-message();
    > label {
      line-height: 18px;
      width: 63px;
      @media (max-width: $global-media-width) and (min-width: 626px) {
        width: 56px;
      }
      @media (max-width: $change-font-size-width) {
        width: 50px;
      }
    }
    > .provider {
      display: block;
      flex: 1;
      max-width: 223px;
      @media (max-width: 625px) {
        max-width: 261px;
      }
    }
  }
}
</style>
