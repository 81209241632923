<template>
  <div class="base-wide-text-box">
    <input
      ref="wideTextBox"
      class="wide-text-box"
      :value="value"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      @input="inputText"
      @blur="$emit('blur')"
      @keyup="$emit('keyup')"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseWideTextBox',

  props: {
    value: { type: [String, Number] },
    name: { type: String, default: '' },
    type: { type: String, default: 'text' },
    placeholder: { type: String },
    maxlength: { type: Number, default: 255 }
  },

  methods: {
    inputText(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-wide-text-box {
  > .wide-text-box {
    width: 600px;
    height: 45px;
    border-radius: 5px;
    border: solid 2px #{$plus_ce_gray};
    background-color: #{$plus_white};
    padding: 2px 14px;
    @include font-size(18);
    text-align: left;
    box-sizing: border-box;
    &::placeholder {
      color: #{$plus_be_gray};
    }
    &::-ms-clear {
      display: none;
    }
    &::-ms-reveal {
      display: none;
    }
  }
}
@media (max-width: $global-media-width) {
  .base-wide-text-box {
    > .wide-text-box {
      width: 100%;
    }
  }
}
</style>
